import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Grid,
  Box,
  Paper,
  IconButton,
  Button,
  ButtonGroup,
  Typography
} from '@material-ui/core';
import {DataGrid, GridToolbar} from '@material-ui/data-grid'
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import PageContainer from '../../components/container/PageContainer';
import http from '../../lib/http';
import ExcelExportActions from '../../components/button/ExcelExport';


function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };


  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const BCrumb = [
  {
    to: '/',
    title: 'Dashboard',
  },
  {
    title: 'Daftar Pendaftaran',
  },
];

const Invoices = () => {
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [stats, setStats] = React.useState({})
  const [users, setUsers] = React.useState([])
  // const [total, setTotal] = React.useState(0)
  // const [page, setPage] = React.useState(0)
  const [schedule, setSchedule] = React.useState({
    name: "",
    facilitator: ""
  })
  const [dataExport, setDataExport] = React.useState([]);
  const [selectRowId, setSelectRowId] = React.useState();
  const params = useParams();
  const [ openDialog, setOpenDialog ] = React.useState(false);  


  const handleOpenDialog = (selectedId) => {
    setSelectRowId(selectedId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };


  // Avoid a layout jump when reaching the last page with empty rows.

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };


  function fetchInvoices() {
    http.get(`admin/${params.slug}/invoices`, {params: {
        page: 0,
        per_page: 300
    }}).then((response) => {
        setUsers(response.data.data)
        setSchedule(response.data.meta.schedule)
    })
  }

  function getStatistics() {
    http.get(`admin/statistics/${params.slug}/invoices`).then((response) => {
      setStats({...response.data.data})
    })
  }

  async function getAllUsers() {
    await http.get(`admin/${params.slug}/allInvoices`).then((res) => {
      setDataExport(res.data.data);
    });
  }

  React.useEffect(() => {
    if (users.length > 0)
        setUsers([])

    if (params.slug) {
        fetchInvoices();
        getAllUsers();
        getStatistics();
    }
  }, [params.slug])

  const deleteRow = (_id) => {
    // eslint-disable-next-line no-alert
    if (window.confirm("Hapus?"))
    http.delete(`admin/invoice/${_id}`).then(() => {
        fetchInvoices();
    })
  }

  // const updateStatus = (_id) => {
  //   http.patch(`admin/invoice/paid/${_id}`).then(() => {
  //       fetchInvoices();
  //   })
  // }

  const updateApprovalFlag = (id, status) => {
    if(status === false) {
      http.post(`admin/invoice/approval/${id}`,{
        approve: false,
      }).then(() => {
        fetchInvoices()
      });
    } else if(status === true) {
      http.post(`admin/invoice/approval/${id}`,{
        approve: true,
      }).then(() => {
        fetchInvoices()
      });
    }
    setOpenDialog(false);
  }

  const generateLink = (val) => {
    navigator.clipboard.writeText(`https://kjr.tarbiyahsunnah.com/${val}`);
    // eslint-disable-next-line no-alert
    alert('Link Pendaftaran Telah di Copy!');
  }

  const viewDetail = (id) => {
    const url = `https://kjr.tarbiyahsunnah.com/confirmation/${id}`
    window.open(url, '_blank').focus();
  }

  const columns = [
    { field: '_id', headerName: 'ID', hide: true },
    { field: 'code', headerName: 'Kode', width:228 },
    { field: 'waiting_list', headerName: 'Waiting List?', width:167 },
    { field: 'name', headerName: 'Nama', width:250 },
    { field: 'city', headerName: 'Asal', width:190 },
    { field: 'phone', headerName: 'Handphone', width:180 },
    { field: 'email', headerName: 'Email', width:180 },
    { field: 'total_payable', headerName: 'Infaq', width:180 },
    { field: 'paid_confirm', headerName: 'Konfirm Bayar?', width:185 },
    { 
      field: 'info_mahram',
      headerName: 'Info Mahram', 
      width:280,
      valueGetter:(v) => {
        if(!v.row.mahram_name) {
          return "-";
        }
        return `${v.row.mahram_name} : ${v.row.mahram_contact}`;
      },
    },
    { field: 'approve', headerName: 'Status Persetujuan', width:180, type: 'boolean' },
    { 
      field: 'command',
      headerName: 'Command',
      width:350,
      renderCell: (rowParams) => {
        return (
          <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
            {
              (rowParams.row.approve === "")  ?
              <Button
                onClick={() => handleOpenDialog(rowParams.id)}>
                  Ubah Status
              </Button> : 
              <>
                &nbsp;
              </>
            }
            &nbsp;
            {
              (rowParams.row.approve === "true")  ?
              <Button
                onClick={()=>generateLink(rowParams.id)}>
                  Lihat Link
              </Button> : 
              <>
                &nbsp;
              </>
            }

            &nbsp;
            <Button
              color='info'
              onClick={()=>viewDetail(rowParams.id)}>
                Lihat Detil
            </Button>
            
            &nbsp;
            <Button
              color='error'
              onClick={()=>deleteRow(rowParams.id)}>
                Hapus
            </Button>
          </ButtonGroup>
      )}
    }
  ]
  return (
    <PageContainer title="Jadwal Kajian" description="this is Pagination Table page">
      {/* breadcrumb */}
      <Breadcrumb title={schedule.name.concat(' - ').concat(schedule.facilitator)} items={BCrumb} />
      {/* end breadcrumb */}
      <Card>
        <CardContent>
          <Typography textAlign='center' fontSize='h2'>Ringkasan Pendaftaran</Typography>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Paper>
                <Typography fontSize='h6'>Jumlah Semua Pendaftaran  </Typography>
                <Typography fontSize='body1'>{stats.total} </Typography>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper>
                <Typography fontSize='h6'>Jumlah Yang Disetujui</Typography>
                <Typography fontSize='body1'>{stats.approved} </Typography>
              </Paper>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Box
            sx={{
              overflow: {
                xs: 'auto',
                sm: 'unset',
              },
            }}
          >
            {dataExport.length > 0 ?
            <ExcelExportActions data={dataExport}/> : ''} 
            <div style={{ height: 550, width: '100%' }}>
              <DataGrid 
                rows={users} 
                columns={columns} 
                scrollbarSize={50}
                sx={{ overflowX: 'scroll' }}
                components={{Toolbar:GridToolbar,}}
                getRowId={(row) => row._id}
                pageSizeOptions={[250]}
              />
            </div>
          </Box>
        </CardContent>
      </Card>
      
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Ubah Status Pendaftar</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Apakah Antum akan merubah Status Pendaftaran ini ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => updateApprovalFlag(selectRowId, false)} color="primary">
            Ditolak
          </Button>
          <Button onClick={() => updateApprovalFlag(selectRowId, true)} color="primary" autoFocus>
            Disetujui
          </Button>
        </DialogActions>
      </Dialog>
    </PageContainer>
  );
};

export default Invoices;
